import { createContext, useState, useEffect } from "react";
import {
  majorChords,
  minorChords,
  seventhChords,
  variantChords,
} from "../assets/chords";

export const ChordsContext = createContext();

export const ChordsProvider = ({ children }) => {
  const [transpose, setTranspose] = useState(0);
  const [resetTranspose, setResetTranspose] = useState(0);
  const [transposeUp, setTransposeUp] = useState(0);
  const [transposeDown, setTransposeDown] = useState(0);
  const [doTransposeUp, setDoTransposeUp] = useState(false);
  const [doTransposeDown, setDoTransposeDown] = useState(false);
  const [transposedScale, setTransposedScale] = useState(0);
  const [transposedChords, setTransposedChords] = useState([]);
  const [transposedScaledImage, setTransposedScaledImage] = useState(0);

  const [hymnId, setHymnId] = useState(null);
  const [hymnNumber, setHymnNumber] = useState(null);
  const [fetchedChords, setFetchedChords] = useState([]);

  const [editMode, setEditMode] = useState(false);
  const [saveMode, setSaveMode] = useState(false);
  const [editDetailsMode, setEditDetailsMode] = useState(false);
  const [notesMode, setNotesMode] = useState(false);
  const [currentScale, setCurrentScale] = useState("");
  const [search, setSearch] = useState("");
  const [results, setResults] = useState([]);
  const [allHymns, setAllHymns] = useState([]);
  const [notesPdfUrl, setNotesPdfUrl] = useState("");

  const [htmlContent, setHtmlContent] = useState("");
  const [initialHtmlContent, setInitialHtmlContent] = useState("");

  const allChordsObj = [].concat(
    minorChords,
    majorChords,
    seventhChords,
    variantChords
  );

  const allChords = []
    .concat(minorChords, majorChords, seventhChords, variantChords)
    .map((c) => c.value);

  const useWindowWidth = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    return width;
  };
  const windowWidth = useWindowWidth();
  const [zoom, setZoom] = useState(windowWidth <= 768 ? 0.8 : 1);

  return (
    <ChordsContext.Provider
      value={{
        resetTranspose,
        setResetTranspose,
        transposeUp,
        setTransposeUp,
        transposeDown,
        setTransposeDown,
        hymnId,
        setHymnId,
        hymnNumber,
        setHymnNumber,
        fetchedChords,
        setFetchedChords,
        editMode,
        setEditMode,
        saveMode,
        setSaveMode,
        editDetailsMode,
        setEditDetailsMode,
        notesMode,
        setNotesMode,
        search,
        setSearch,
        results,
        setResults,
        zoom,
        setZoom,
        allHymns,
        setAllHymns,
        notesPdfUrl,
        setNotesPdfUrl,
        transpose,
        setTranspose,
        transposedScale,
        setTransposedScale,
        transposedChords,
        setTransposedChords,
        transposedScaledImage,
        setTransposedScaledImage,
        doTransposeUp,
        setDoTransposeUp,
        doTransposeDown,
        setDoTransposeDown,
        currentScale,
        setCurrentScale,
        htmlContent,
        setHtmlContent,
        initialHtmlContent,
        setInitialHtmlContent,
        allChordsObj,
        allChords,
      }}
    >
      {children}
    </ChordsContext.Provider>
  );
};
