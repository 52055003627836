function showUpdateNotification(registration) {
  const styles = `
    .install-prompt {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: #141156;
      color: #fff;
      padding: 30px;
      text-align: center;
      box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3);
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 1000;
    }

    .install-prompt button {
      margin-top: 10px;
      padding: 10px 20px;
      background-color: #3498db;
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }

    /* Responsive styling for mobile devices */
    @media (min-width: 600px) {
      .install-prompt {
        flex-direction: row;
        justify-content: space-between;
        padding: 15px;
      }

      .install-prompt button {
        margin-top: 0;
      }
    }
  `;

  const styleSheet = document.createElement("style");
  styleSheet.type = "text/css";
  styleSheet.innerText = styles;
  document.head.appendChild(styleSheet);

  // Create the install prompt element
  const prompt = document.createElement("div");
  prompt.classList.add("install-prompt");
  prompt.innerHTML = `<p>Μία νέα έκδοση είναι διαθέσιμη! Παρακαλώ ανανεώστε την εφαρμογή.</p>`;

  const refreshButton = document.createElement("button");
  refreshButton.innerText = "Ανανέωση";
  refreshButton.addEventListener("click", () => {
    if (registration.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
    window.location.reload();
  });

  prompt.appendChild(refreshButton);
  document.body.appendChild(prompt);
}

export default showUpdateNotification;
