import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, createSearchParams } from "react-router-dom";
import { List, ListItem, ListItemText, Divider } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { HymnSearchModal } from "../components/HymnSearchModal";

const CategoryDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [categoryContent, setCategoryContent] = useState([]);
  const [categoryIndex, setCategoryIndex] = useState([]);
  const [showHymnModal, setShowHymnModal] = useState(false);
  const [selectedHymns, setSelectedHymns] = useState([]);
  const [state] = useState(location.state);

  useEffect(() => {
    if (state) {
      const categoryContent =
        JSON.parse(localStorage.getItem("categoryContent")) || [];
      setCategoryContent(
        categoryContent.length > 0 ? categoryContent : state.content
      );
      setCategoryIndex(state.index);
      setCategories(state.categories || []);
      localStorage.setItem(
        "categoryContent",
        JSON.stringify(categoryContent || state.content)
      );
    }
    if (!state) {
      return <div>Η κατηγορία δεν βρέθηκε.</div>;
    }
  }, [state]);

  const handleClick = (value) => {
    navigate({
      pathname: "/Hymn",
      search: createSearchParams({
        title: value,
      }).toString(),
    });
  };

  const handleSelectedHymnsChange = (newSelectedHymns) => {
    setSelectedHymns(newSelectedHymns);
  };

  const handleAddSelectedHymns = () => {
    const updatedCategories = [...categories];
    const newHymns = selectedHymns.filter(
      (hymn) => !updatedCategories[categoryIndex].content.includes(hymn)
    );
    updatedCategories[categoryIndex].content = [
      ...updatedCategories[categoryIndex].content,
      ...newHymns,
    ];
    setCategoryContent(updatedCategories[categoryIndex].content);
    localStorage.setItem("favorites", JSON.stringify(updatedCategories));
    setSelectedHymns([]);
    setShowHymnModal(false);
    localStorage.setItem(
      "categoryContent",
      JSON.stringify(updatedCategories[categoryIndex].content)
    );
  };

  const handleRemoveHymnFromCategory = (hymnIndex, event) => {
    event.stopPropagation();
    const updatedCategories = [...categories];
    updatedCategories[categoryIndex].content = updatedCategories[
      categoryIndex
    ].content.filter((_, index) => index !== hymnIndex);
    setCategoryContent(updatedCategories[categoryIndex].content);
    localStorage.setItem("favorites", JSON.stringify(updatedCategories));
    localStorage.setItem(
      "categoryContent",
      JSON.stringify(updatedCategories[categoryIndex].content)
    );
  };

  return (
    <>
      <Modal show={showHymnModal} onHide={() => setShowHymnModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Αναζήτηση Ύμνων</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <HymnSearchModal onSelectedHymnsChange={handleSelectedHymnsChange} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowHymnModal(false)}>
            Άκυρο
          </Button>
          <Button
            variant="primary"
            onClick={handleAddSelectedHymns}
            disabled={selectedHymns.length === 0}
          >
            Αποθήκευση
          </Button>
        </Modal.Footer>
      </Modal>

      <List>
        {categoryContent.map((hymn, index) => (
          <React.Fragment key={index}>
            <ListItem
              button
              onClick={() => handleClick(hymn)}
              style={{ justifyContent: "space-between" }}
            >
              <ListItemText primary={hymn} />
              <ClearIcon
                onClick={(e) => handleRemoveHymnFromCategory(index, e)}
                style={{ cursor: "pointer" }}
              />
            </ListItem>
            {index !== categoryContent.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>

      <div className="add-category">
        <div
          onClick={() => setShowHymnModal(true)}
          style={{ display: "inline-block", cursor: "pointer" }}
        >
          <AddCircleIcon style={{ fontSize: 40 }} />
        </div>
      </div>
    </>
  );
};

export default CategoryDetails;
