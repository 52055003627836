import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Popover,
  ListItemIcon,
  MenuItem,
  Divider,
} from "@material-ui/core";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import "./Favorites.css";

export const Favorites = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuIndex, setMenuIndex] = useState(null);
  const [categories, setCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newCategoryTitle, setNewCategoryTitle] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState();
  const [deleteTitle, setDeleteTitle] = useState("");
  const [editOpen, setEditOpen] = useState(false);
  const [editIndex, setEditIndex] = useState();
  const [editTitle, setEditTitle] = useState("");

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem("favorites")) || [];
    setCategories(storedFavorites);
    localStorage.setItem("categoryContent", JSON.stringify([]));
  }, []);

  const handleMenuClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setMenuIndex(index);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuIndex(null);
  };

  const handleAddCategory = () => {
    const newCategories = [
      ...categories,
      { title: newCategoryTitle, content: [] },
    ];
    setCategories(newCategories);
    localStorage.setItem("favorites", JSON.stringify(newCategories));

    setNewCategoryTitle("");
    setShowModal(false);
  };

  const handleDeleteCategory = (indexToRemove) => {
    const updatedCategories = categories.filter(
      (_, index) => index !== indexToRemove
    );
    setCategories(updatedCategories);
    localStorage.setItem("favorites", JSON.stringify(updatedCategories));
  };

  const handleEditCategory = () => {
    if (editIndex !== null) {
      const updatedCategories = [...categories];
      updatedCategories[editIndex].title = editTitle;
      setCategories(updatedCategories);
      localStorage.setItem("favorites", JSON.stringify(updatedCategories));
    }
    setEditOpen(false);
  };

  const openCategoryDetails = (index) => {
    navigate("/Category?title=" + categories[index].title, {
      state: {
        title: categories[index].title,
        content: categories[index].content,
        index: index,
        categories: categories,
      },
    });
  };

  return (
    <>
      {/* categories */}
      <List>
        {categories.map((item, index) => (
          <div key={index}>
            <ListItem
              button
              onClick={() => openCategoryDetails(index)}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <ListItemText primary={item.title} />
              <div style={{ display: "flex", alignItems: "center" }}>
                <MoreVertIcon
                  onClick={(event) => {
                    event.stopPropagation();
                    handleMenuClick(event, index);
                  }}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </ListItem>
            <Divider style={{ margin: "5px 0", backgroundColor: "grey" }} />
            {index === menuIndex && (
              <Popover
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem
                  onClick={() => {
                    setEditIndex(index);
                    setEditTitle(item.title);
                    setEditOpen(true);
                    handleMenuClose();
                  }}
                >
                  <ListItemIcon style={{ margin: 0, padding: 0, minWidth: 20 }}>
                    <EditIcon />
                  </ListItemIcon>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setDeleteIndex(index);
                    setDeleteTitle(item.title);
                    setDeleteOpen(true);
                    handleMenuClose();
                  }}
                >
                  <ListItemIcon style={{ margin: 0, padding: 0, minWidth: 20 }}>
                    <DeleteIcon />
                  </ListItemIcon>
                </MenuItem>
              </Popover>
            )}
          </div>
        ))}
      </List>

      <div className="add-category">
        <div
          onClick={() => setShowModal(true)}
          style={{ display: "inline-block", cursor: "pointer" }}
        >
          <AddCircleIcon style={{ fontSize: 40 }} />
        </div>
      </div>

      {/* edit dialog */}
      <Dialog
        open={editOpen}
        onClose={() => setEditOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Title"
            type="text"
            fullWidth
            value={editTitle}
            onChange={(e) => setEditTitle(e.target.value)}
          />
        </DialogTitle>
        <DialogActions>
          <Button variant="secondary" onClick={() => setEditOpen(false)}>
            Άκυρο
          </Button>
          <Button
            onClick={() => {
              handleEditCategory(deleteIndex);
              setEditOpen(false);
            }}
            autoFocus
          >
            Επιβεβαίωση
          </Button>
        </DialogActions>
      </Dialog>

      {/* delete dialog */}
      <Dialog
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Είστε σίγουροι για την διαγραφή της κατηγορίας {deleteTitle};
        </DialogTitle>
        <DialogActions>
          <Button variant="secondary" onClick={() => setDeleteOpen(false)}>
            Άκυρο
          </Button>
          <Button
            onClick={() => {
              handleDeleteCategory(deleteIndex);
              setDeleteOpen(false);
            }}
            autoFocus
          >
            Ναι
          </Button>
        </DialogActions>
      </Dialog>

      {/* add modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Τίτλος Κατηγορίας</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="text"
            placeholder="Εισαγωγή τίτλου"
            value={newCategoryTitle}
            onChange={(e) => setNewCategoryTitle(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Άκυρο
          </Button>
          <Button
            variant="primary"
            onClick={handleAddCategory}
            disabled={!newCategoryTitle.trim().length}
          >
            Αποθήκευση
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Favorites;
