import { useEffect, useContext, useState } from "react";
import { ChordsContext } from "./ChordsContext";
import { majorScales, minorScales, flatScales } from "../assets/scales";

export default function Transpose() {
  // #region consts
  const {
    resetTranspose,
    setTransposeUp,
    setTransposeDown,
    setNotesMode,
    allChordsObj,
    transposeUp,
    transposeDown,
    doTransposeDown,
    setDoTransposeDown,
    doTransposeUp,
    setDoTransposeUp,
    currentScale,
    setTransposedScaledImage,
    transpose,
    setTranspose,
    setTransposedScale,
    setTransposedChords,
    fetchedChords,
    setFetchedChords,
    htmlContent,
    setHtmlContent,
  } = useContext(ChordsContext);
  const [isFlatScale, setIsFlatScale] = useState(false);
  // #endregion

  // #region effects

  useEffect(() => {
    setTranspose(0);
    handleScaleChange(0);
    setNotesMode(false);
    // eslint-disable-next-line
  }, [resetTranspose]);

  useEffect(() => {
    if (doTransposeUp) runTransposeUp();
    setDoTransposeUp(false);
    // eslint-disable-next-line
  }, [doTransposeUp]);

  useEffect(() => {
    if (doTransposeDown) runTransposeDown();
    setDoTransposeDown(false);
    // eslint-disable-next-line
  }, [doTransposeDown]);

  useEffect(() => {
    handleScaleChange(transpose);
    // eslint-disable-next-line
  }, [currentScale]);

  useEffect(() => {
    handleTranspose();
    // eslint-disable-next-line
  }, [transposeUp, transposeDown]);
  // #endregion

  // #region run transpose OK
  const runTransposeUp = () => {
    if (transpose < 11) {
      setTranspose(transpose + 1);
      handleScaleChange(transpose + 1);
    } else {
      setTranspose(0);
      handleScaleChange(0);
    }
    setTransposeUp((prev) => prev + 1);
    setTransposeDown(0);
  };

  const runTransposeDown = () => {
    if (transpose > -11) {
      setTranspose(transpose - 1);
      handleScaleChange(transpose - 1);
    } else {
      setTranspose(0);
      handleScaleChange(0);
    }
    setTransposeDown((prev) => prev + 1);
    setTransposeUp(0);
  };
  // #endregion

  // #region scale
  const handleScaleChange = (transpose) => {
    let newScale = transposeScale(currentScale, transpose);
    setTransposedScale(newScale.label);
    setTransposedScaledImage(newScale.image);
    let isFlatScale = calculateFlatScale(newScale.value);
    setIsFlatScale(isFlatScale);
  };

  const calculateFlatScale = (currentScale) => {
    return flatScales.some((scale) => scale.value === currentScale);
  };

  const transposeScale = (currentScale, transpose) => {
    const isMajorScale = majorScales.some(
      (majorScale) => majorScale.label === currentScale
    );
    const isMinorScale = minorScales.some(
      (minorScale) => minorScale.label === currentScale
    );
    let transposedScaleObj = currentScale;
    if (isMajorScale) {
      transposedScaleObj = calculateTransposedScale(
        currentScale,
        majorScales,
        transpose
      );
    }
    if (isMinorScale) {
      transposedScaleObj = calculateTransposedScale(
        currentScale,
        minorScales,
        transpose
      );
    }
    return transposedScaleObj;
  };

  const calculateTransposedScale = (currentScale, scalesList, transpose) => {
    const scaleIndex = scalesList.findIndex(
      (scale) => scale.label === currentScale
    );
    if (scaleIndex !== -1) {
      if (transpose === 0) {
        return {
          value: currentScale,
          label: currentScale,
          image: scalesList[scaleIndex].image,
        };
      } else if (transpose > 0) {
        let nextScaleIndex = (scaleIndex + transpose) % scalesList.length;
        if (nextScaleIndex < 0) {
          nextScaleIndex += scalesList.length;
        }
        return {
          value: scalesList[nextScaleIndex].value,
          label: scalesList[nextScaleIndex].label,
          image: scalesList[nextScaleIndex].image,
        };
      } else if (transpose < 0) {
        const maxOrder = Math.max(...scalesList.map((scale) => scale.order));
        let prevScaleIndex =
          (scaleIndex + maxOrder + transpose) % scalesList.length;
        if (prevScaleIndex < 0) {
          prevScaleIndex += scalesList.length;
        }
        return {
          value: scalesList[prevScaleIndex].value,
          label: scalesList[prevScaleIndex].label,
          image: scalesList[prevScaleIndex].image,
        };
      }
    }
    return {
      value: currentScale,
      label: currentScale,
      image: null,
    };
  };
  // #endregion

  // #region chords

  const handleTranspose = () => {
    const updatedChords = fetchedChords.map((chord) => transposeChord(chord));
    let newHtmlContent = htmlContent;
    const replacements = {};
    for (let i = 0; i < fetchedChords.length; i++) {
      replacements[fetchedChords[i]] = updatedChords[i];
    }

    newHtmlContent = newHtmlContent.replace(
      new RegExp(
        `(${Object.keys(replacements)
          .sort((a, b) => b.length - a.length)
          .map((r) => r.replace("#", "\\#"))
          .join("|")})`,
        "g"
      ),
      (matched) => replacements[matched]
    );

    setHtmlContent(newHtmlContent);
    setFetchedChords(updatedChords);

    const transposedChords = allChordsObj
      .filter(({ value }) => updatedChords.includes(value))
      .map((chord) => {
        let originalLabel = updatedChords[updatedChords.indexOf(chord.value)];
        return { ...chord, label: originalLabel };
      });

    setTransposedChords(transposedChords);
  };

  const baseChords = [
    "A",
    "A#",
    "B",
    "C",
    "C#",
    "D",
    "D#",
    "E",
    "F",
    "F#",
    "G",
    "G#",
  ];

  const transposeChord = (chord) => {
    let chordBase = chord.replace("m", "").replace("7", "");
    const chordSuffix = chord.slice(chordBase.length);

    if (!isFlatScale) {
      switch (chordBase) {
        case "Bb":
          chordBase = "A#";
          break;
        case "Db":
          chordBase = "C#";
          break;
        case "Eb":
          chordBase = "D#";
          break;
        case "Gb":
          chordBase = "F#";
          break;
        case "Ab":
          chordBase = "G#";
          break;
        default:
          break;
      }
    }

    let chordIndex = baseChords.indexOf(chordBase);
    if (chordIndex === -1) {
      return chord;
    }

    if (isFlatScale) {
      switch (chordBase) {
        case "A#":
          chordBase = "Bb";
          break;
        case "C#":
          chordBase = "Db";
          break;
        case "D#":
          chordBase = "Eb";
          break;
        case "F#":
          chordBase = "Gb";
          break;
        case "G#":
          chordBase = "Ab";
          break;
        default:
          break;
      }
    } else {
      switch (chordBase) {
        case "Bb":
          chordBase = "A#";
          break;
        case "Db":
          chordBase = "C#";
          break;
        case "Eb":
          chordBase = "D#";
          break;
        case "Gb":
          chordBase = "F#";
          break;
        case "Ab":
          chordBase = "G#";
          break;
        default:
          break;
      }
    }

    let newIndex = chordIndex + (transposeUp ? 1 : transposeDown ? -1 : 0);
    if (newIndex >= baseChords.length) {
      newIndex -= baseChords.length;
    } else if (newIndex < 0) {
      newIndex += baseChords.length;
    }

    let transposedChord = baseChords[newIndex];

    if (isFlatScale) {
      switch (transposedChord) {
        case "A#":
          transposedChord = "Bb";
          break;
        case "C#":
          transposedChord = "Db";
          break;
        case "D#":
          transposedChord = "Eb";
          break;
        case "F#":
          transposedChord = "Gb";
          break;
        case "G#":
          transposedChord = "Ab";
          break;
        default:
          break;
      }
    } else {
      switch (transposedChord) {
        case "Bb":
          transposedChord = "A#";
          break;
        case "Db":
          transposedChord = "C#";
          break;
        case "Eb":
          transposedChord = "D#";
          break;
        case "Gb":
          transposedChord = "F#";
          break;
        case "Ab":
          transposedChord = "G#";
          break;
        default:
          break;
      }
    }

    return transposedChord + chordSuffix;
  };
  // #endregion
}
