export const majorScales = [
  {
    order: 1,
    value: "C Major",
    label: "C (C Major)",
    image: require("../assets/images/c_major.png"),
  },
  {
    order: 2,
    value: "C Sharp Major",
    label: "C# (C Sharp Major)",
    image: require("../assets/images/c_sharp_major.png"),
  },
  // {
  //   order: 2,
  //   value: "D Flat Major",
  //   label: "D♭ (D Flat Major)",
  //   image: require("../assets/images/d_flat_major.png"),
  // },
  {
    order: 3,
    value: "D Major",
    label: "D (D Major)",
    image: require("../assets/images/d_major.png"),
  },
  {
    order: 4,
    value: "E Flat Major",
    label: "E♭ (E Flat Major)",
    image: require("../assets/images/e_flat_major.png"),
  },
  {
    order: 5,
    value: "E Major",
    label: "E (E Major)",
    image: require("../assets/images/e_major.png"),
  },
  {
    order: 6,
    value: "F Major",
    label: "F (F Major)",
    image: require("../assets/images/f_major.png"),
  },
  {
    order: 7,
    value: "F Sharp Major",
    label: "F# (F Sharp Major)",
    image: require("../assets/images/f_sharp_major.png"),
  },
  // {
  //   order: 7,
  //   value: "G Flat Major",
  //   label: "G♭ (G Flat Major)",
  //   image: require("../assets/images/g_flat_major.png"),
  // },
  {
    order: 8,
    value: "G Major",
    label: "G (G Major)",
    image: require("../assets/images/g_major.png"),
  },
  {
    order: 9,
    value: "A Flat Major",
    label: "A♭ (A Flat Major)",
    image: require("../assets/images/a_flat_major.png"),
  },
  {
    order: 10,
    value: "A Major",
    label: "A (A Major)",
    image: require("../assets/images/a_major.png"),
  },
  {
    order: 11,
    value: "B Flat Major",
    label: "B♭ (B Flat Major)",
    image: require("../assets/images/b_flat_major.png"),
  },
  {
    order: 12,
    value: "B Major",
    label: "B (B Major)",
    image: require("../assets/images/b_major.png"),
  },
  // {
  //   order: 13,
  //   value: "C Flat Major",
  //   label: "C♭ (C Flat Major)",
  //   image: require("../assets/images/c_flat_major.png"),
  // },
];

export const minorScales = [
  {
    order: 1,
    value: "C Minor",
    label: "Cm (C Minor)",
    image: require("../assets/images/c_minor.png"),
  },
  {
    order: 2,
    value: "C Sharp Minor",
    label: "Cm# (C Sharp Minor)",
    image: require("../assets/images/c_sharp_minor.png"),
  },
  {
    order: 3,
    value: "D Minor",
    label: "Dm (D Minor)",
    image: require("../assets/images/d_minor.png"),
  },
  {
    order: 4,
    value: "D Sharp Minor",
    label: "Dm# (D Sharp Minor)",
    image: require("../assets/images/d_sharp_minor.png"),
  },
  {
    order: 5,
    value: "E Minor",
    label: "Em (E Minor)",
    image: require("../assets/images/e_minor.png"),
  },
  {
    order: 6,
    value: "F Minor",
    label: "Fm (F Minor)",
    image: require("../assets/images/f_minor.png"),
  },
  {
    order: 7,
    value: "F Sharp Minor",
    label: "Fm# (F Sharp Minor)",
    image: require("../assets/images/f_sharp_minor.png"),
  },
  {
    order: 8,
    value: "G Minor",
    label: "Gm (G Minor)",
    image: require("../assets/images/g_minor.png"),
  },
  {
    order: 9,
    value: "G Sharp Minor",
    label: "Gm# (G Sharp Minor)",
    image: require("../assets/images/g_sharp_minor.png"),
  },
  {
    order: 10,
    value: "A Minor",
    label: "Am (A Minor)",
    image: require("../assets/images/a_minor.png"),
  },
  {
    order: 11,
    value: "A Sharp Minor",
    label: "Am# (A Sharp Minor)",
    image: require("../assets/images/a_sharp_minor.png"),
  },
  {
    order: 12,
    value: "B Minor",
    label: "Bm (B Minor)",
    image: require("../assets/images/b_minor.png"),
  },
  // {
  //   value: "A Flat Minor",
  //   label: "Am♭ (A Flat Minor)",
  //   image: require("../assets/images/a_flat_minor.png"),
  // },
  // {
  //   value: "E Flat Minor",
  //   label: "Em♭ (E Flat Minor)",
  //   image: require("../assets/images/e_flat_minor.png"),
  // },
  // {
  //   value: "B Flat Minor",
  //   label: "Bm♭ (B Flat Minor)",
  //   image: require("../assets/images/b_flat_minor.png"),
  // },
];

export const flatScales = [
  {
    value: "E♭ (E Flat Major)",
  },
  {
    value: "F Major",
  },
  {
    value: "A Flat Major",
  },
  {
    value: "B Flat Major",
  },
  {
    value: "C Flat Major",
  },
  {
    value: "C Minor",
  },
  {
    value: "D Minor",
  },
  {
    value: "F Minor",
  },
  {
    value: "G Minor",
  },
];
