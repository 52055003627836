import React, { useState, useEffect, useRef } from "react";
import FrequencyBars, {
  TunerCore,
  Notes,
  Meter,
} from "../components/TunerHelpers";
import "./Tuner.css";

const Tuner = () => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);
  const [scriptStatus, setScriptStatus] = useState({
    loaded: false,
    error: false,
  });

  const useScript = (src) => {
    const [status, setStatus] = useState({
      loaded: false,
      error: false,
    });

    useEffect(() => {
      let script = document.querySelector(`script[src="${src}"]`);

      const onScriptLoad = () => setStatus({ loaded: true, error: false });
      const onScriptError = () => setStatus({ loaded: false, error: true });

      if (!script) {
        script = document.createElement("script");
        script.src = src;
        script.async = true;
        document.body.appendChild(script);

        script.addEventListener("load", onScriptLoad);
        script.addEventListener("error", onScriptError);
      } else {
        setStatus({ loaded: true, error: false });
      }

      return () => {
        if (script) {
          script.removeEventListener("load", onScriptLoad);
          script.removeEventListener("error", onScriptError);
        }
      };
    }, [src]);

    return status;
  };

  const [frequencyData, setFrequencyData] = useState([]);
  const notesRef = useRef(null);
  const meterRef = useRef(null);
  const status = useScript("https://unpkg.com/@babel/standalone/babel.min.js");

  useEffect(() => {
    setScriptStatus(status);
  }, [status]);

  useEffect(() => {
    if (scriptStatus.loaded) {
      const tuner = new TunerCore(440);
      const notes = new Notes(".notes", tuner);
      const meter = new Meter(".meter");
      notesRef.current = notes;
      meterRef.current = meter;

      tuner.onNoteDetected = (note) => {
        if (notesRef.current && meterRef.current) {
          notesRef.current.update(note);
          meterRef.current.update((note.cents / 50) * 45);
        }
      };

      tuner.init();
      // Periodically update frequency data
      const updateFrequencyData = () => {
        const data = tuner.getFrequencyData();
        setFrequencyData(data);
        requestAnimationFrame(updateFrequencyData);
      };

      updateFrequencyData();
    }
  }, [scriptStatus.loaded]);

  return (
    <div className="d-flex justify-center">
      <div className="frequency-bars">
        <FrequencyBars data={frequencyData} />
      </div>
      <div className="meter">
        <div className="meter-dot"></div>
        <div className="meter-pointer"></div>
      </div>
      <div className="notes">
        <div className="notes-list"></div>
        <div className="frequency">
          <span>Hz</span>
        </div>
      </div>
    </div>
  );
};

export default Tuner;
