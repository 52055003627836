import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { List, ListItem } from "@material-ui/core";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";

const AddToFavorites = ({
  showAddToFavoritesModal,
  setShowAddToFavoritesModal,
  addHymnToFavorites,
}) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem("favorites")) || [];
    setCategories(storedFavorites);

    const initialSelectedCategories = storedFavorites.reduce(
      (acc, item, index) => {
        if (item.content && item.content.includes(addHymnToFavorites)) {
          acc.push(index);
        }
        return acc;
      },
      []
    );
    setSelectedCategories(initialSelectedCategories);
  }, [addHymnToFavorites, showAddToFavoritesModal]);

  const handleAddHymnToSelectedCategories = () => {
    const updatedCategories = [...categories];
    if (!selectedCategories || selectedCategories.length === 0) {
      updatedCategories.forEach((item) => {
        item.content = item.content.filter(
          (hymn) => hymn !== addHymnToFavorites
        );
      });
    } else {
      updatedCategories.forEach((item, index) => {
        if (selectedCategories.includes(index)) {
          updatedCategories[index].content = [
            ...updatedCategories[index].content,
            addHymnToFavorites,
          ];
        }
      });
    }
    localStorage.setItem("favorites", JSON.stringify(updatedCategories));
    setSelectedCategories([]);
    setShowAddToFavoritesModal(false);
  };

  return (
    <>
      <Modal
        show={showAddToFavoritesModal}
        onHide={() => setShowAddToFavoritesModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontFamily: "Manrope, sans-serif" }}>
            Επιλογή λίστας αγαπημένων
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {categories.length > 0 && (
            <List style={{ width: "100%", fontFamily: "Manrope, sans-serif" }}>
              {categories.map((item, index) => (
                <ListItem key={index} divider={index !== categories.length - 1}>
                  <Checkbox
                    checked={selectedCategories.includes(index)}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setSelectedCategories((prevSelectedCategories) => [
                          ...prevSelectedCategories,
                          index,
                        ]);
                      } else {
                        setSelectedCategories((prevSelectedCategories) =>
                          prevSelectedCategories.filter(
                            (category) => category !== index
                          )
                        );
                      }
                    }}
                  />
                  <ListItemText
                    primary={`${item.title} ${
                      item.number ? `(${item.number})` : ""
                    }`}
                  />
                </ListItem>
              ))}
            </List>
          )}
          {categories.length === 0 && (
            <div style={{ fontFamily: "Manrope, sans-serif" }}>
              Δεν βρέθηκαν λίστες αγαπημένων. Μπορείτε να προσθέσετε τις λίστες
              σας από το μενού "αγαπημένα".
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowAddToFavoritesModal(false)}
          >
            Άκυρο
          </Button>
          <Button
            variant="primary"
            onClick={handleAddHymnToSelectedCategories}
            disabled={categories.length === 0}
          >
            Αποθήκευση
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddToFavorites;
