import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { initializeFirestore, persistentLocalCache } from "firebase/firestore";
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyCOfTUStgufs-9OEKetvqWyL1pp7XWIxcg",
    authDomain: "hymnsapp-52333.firebaseapp.com",
    projectId: "hymnsapp-52333",
    storageBucket: "hymnsapp-52333.appspot.com",
    messagingSenderId: "747255680480",
    appId: "1:747255680480:web:4684ceec2660d8707e2f85",
    measurementId: "G-Q819B0E8ZH"
  };
  
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Initialize Firestore with persistent local cache
const db = initializeFirestore(app, {
    localCache: persistentLocalCache({
        // Customize your cache settings if necessary
    })
});
const storage = getStorage(app);




export { app, auth, db, storage };
