function showIosInstallPrompt() {
  const styles = `
    .install-prompt {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: #141156;
      color: #fff;
      padding: 10px;
      text-align: center;
      box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3);
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 1000;
    }

    .install-prompt button {
      margin-top: 10px;
      padding: 10px 20px;
      background-color: #3498db;
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }

    /* Responsive styling for mobile devices */
    @media (min-width: 600px) {
      .install-prompt {
        flex-direction: row;
        justify-content: space-between;
        padding: 15px;
      }

      .install-prompt button {
        margin-top: 0;
      }
    }
  `;

  const styleSheet = document.createElement("style");
  styleSheet.type = "text/css";
  styleSheet.innerText = styles;
  document.head.appendChild(styleSheet);

  // Create the install prompt element
  const prompt = document.createElement("div");
  prompt.classList.add("install-prompt");
  prompt.innerHTML = `
    <p>Για να εγκαταστήσετε την εφαρμογή, πατήστε το εικονίδιο <strong>Κοινή χρήση</strong> και μετά επιλέξτε <strong>Προσθήκη στην αρχική οθόνη</strong>.</p>
  `;

  const closeButton = document.createElement("button");
  closeButton.innerText = "Κλείσιμο";
  closeButton.addEventListener("click", () => {
    document.body.removeChild(prompt);
  });

  prompt.appendChild(closeButton);
  document.body.appendChild(prompt);
}

export default showIosInstallPrompt;
