import { React, useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { List, ListItem, TextField } from "@material-ui/core";
import Box from "@mui/material/Box";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { ChordsContext } from "../components/ChordsContext";

export const HymnSearchModal = ({ onSelectedHymnsChange }) => {
  const [selectedHymns, setSelectedHymns] = useState([]);
  const [search, setSearch] = useState("");
  const { allHymns } = useContext(ChordsContext);

  useEffect(() => {
    if (onSelectedHymnsChange) {
      onSelectedHymnsChange(selectedHymns);
    }
  }, [selectedHymns, onSelectedHymnsChange]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const filteredHymns = allHymns.filter((hymn) => {
    if (!hymn || !hymn.title) return false;

    const lowerCaseValue = search.toLowerCase();

    const titleContainsValue = hymn.title
      .toLowerCase()
      .includes(lowerCaseValue);

    const numberContainsValue = hymn.number
      ? hymn.number.includes(lowerCaseValue)
      : false;

    return titleContainsValue || numberContainsValue;
  });

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        bgcolor: "background.paper",
      }}
    >
      <TextField
        type="search"
        variant="outlined"
        onChange={handleSearch}
        InputProps={{
          style: {
            backgroundColor: "#f9f9f9",
            borderRadius: "5px",
            width: "15rem",
            height: "3rem",
          },
        }}
        style={{
          paddingBottom: "2rem",
        }}
        placeholder="Αναζήτηση στην λίστα..."
      />
      <List>
        {filteredHymns.map((item, index) => (
          <ListItem key={index} divider>
            <Checkbox
              checked={selectedHymns.includes(item.title)}
              onChange={(event) => {
                if (event.target.checked) {
                  setSelectedHymns((prevSelectedHymns) => [
                    ...prevSelectedHymns,
                    item.title,
                  ]);
                } else {
                  setSelectedHymns((prevSelectedHymns) =>
                    prevSelectedHymns.filter((title) => title !== item.title)
                  );
                }
              }}
            />
            <ListItemText
              primary={`${item.title} ${item.number ? `(${item.number})` : ""}`}
            />
          </ListItem>
        ))}
      </List>
      <footer style={{ padding: "1rem", textAlign: "right" }}>
        Σύνολο: {filteredHymns.length} από {allHymns.length}
      </footer>
    </Box>
  );
};
