import React, { useState, useEffect, useContext } from "react";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import { useNavigate, createSearchParams } from "react-router-dom";
import { ChordsContext } from "../components/ChordsContext";
import "./Search.css";

export const Search = () => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [previousSearchResults, setPreviousSearchResults] = useState([]);
  const [showPreviousSearchResults, setShowPreviousSearchResults] =
    useState(true);
  const [deleteResult, setDeleteResult] = useState(false);
  const [results, setResults] = useState([]);
  const { allHymns } = useContext(ChordsContext);

  useEffect(() => {
    const previousSearchResults = JSON.parse(
      localStorage.getItem("previousSearchResults")
    );
    if (previousSearchResults) {
      setPreviousSearchResults(previousSearchResults);
    }
  }, []);

  useEffect(() => {
    if (previousSearchResults.length > 0 || deleteResult) {
      localStorage.setItem(
        "previousSearchResults",
        JSON.stringify(previousSearchResults)
      );
    }
  }, [previousSearchResults, deleteResult]);

  const handleSearchResultClick = (result) => {
    var hymnNumber = "";
    if (result.number) {
      hymnNumber = " (" + result.number + ")";
    }
    const newResult = result.title + hymnNumber;

    setPreviousSearchResults((prevResults) => {
      if (prevResults.includes(newResult)) {
        return prevResults;
      }
      return [newResult, ...prevResults];
    });

    setTimeout(() => {
      navigate({
        pathname: "/Hymn",
        search: createSearchParams({
          title: result.title,
        }).toString(),
      });
    }, 0);
  };

  const handlePreviousResultClick = (result) => {
    console.log(result);

    setTimeout(() => {
      navigate({
        pathname: "/Hymn",
        search: createSearchParams({
          title: clearString(result),
        }).toString(),
      });
    }, 0);
  };

  const clearString = (inputString) => {
    const regex = /\(\d+\)/;
    const match = inputString.match(regex);

    if (match) {
      return inputString.substring(0, match.index).trim();
    } else {
      return inputString;
    }
  };

  const handleDeleteResult = (index) => {
    setPreviousSearchResults((prevResults) =>
      prevResults.filter((_, i) => i !== index)
    );
    setDeleteResult(true);
  };

  const normalizeString = (str) => {
    return str
      .normalize("NFD") // Normalize to decomposed form
      .replace(/[\u0300-\u036f]/g, "") // Remove diacritical marks
      .toLowerCase(); // Convert to lowercase
  };

  const filterResults = async (value) => {
    try {
      const normalizedValue = normalizeString(value);
      const results = allHymns.filter((hymn) => {
        if (!value || !hymn || !hymn.title) return false;

        const normalizedTitle = normalizeString(hymn.title);
        const titleContainsValue = normalizedTitle.includes(normalizedValue);

        const numberContainsValue = hymn.number
          ? hymn.number.includes(normalizedValue)
          : false;

        return titleContainsValue || numberContainsValue;
      });
      setResults(results);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    if (event.target.value.trim() !== "") {
      setShowPreviousSearchResults(false);
      filterResults(event.target.value);
    } else {
      setShowPreviousSearchResults(true);
      setResults(null);
    }
  };

  const clearSearchTerm = () => {
    setSearchTerm("");
    setShowPreviousSearchResults(true);
    setResults(null);
  };

  return (
    <Box sx={{ width: "100%", maxWidth: "80%", mx: "auto", mt: 4, pt: 5 }}>
      <TextField
        fullWidth
        label="Αναζήτηση"
        variant="outlined"
        value={searchTerm}
        onChange={handleSearchChange}
        InputProps={{
          endAdornment: searchTerm && (
            <IconButton onClick={clearSearchTerm} size="large">
              <ClearIcon />
            </IconButton>
          ),
          sx: { borderRadius: "20px" },
        }}
        style={{ paddingBottom: "2rem" }}
      />
      {showPreviousSearchResults && previousSearchResults.length > 0 && (
        <List>
          {previousSearchResults.map((result, index) => (
            <ListItem
              key={index}
              className="result"
              onClick={() => handlePreviousResultClick(result)}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteResult(index);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              {result}
            </ListItem>
          ))}
        </List>
      )}
      {results?.length > 0 && (
        <div>
          <List>
            {results.map((result, index) => (
              <ListItem key={index}>
                <div
                  onClick={() => handleSearchResultClick(result)}
                  className="result"
                >
                  {`${result.title} ${
                    result.number ? `(${result.number})` : ""
                  }`}
                </div>
              </ListItem>
            ))}
          </List>
        </div>
      )}
      {!showPreviousSearchResults && results?.length === 0 && (
        <div>
          <div className="no-results">Δεν βρέθηκαν αποτελέσματα</div>
        </div>
      )}
    </Box>
  );
};
