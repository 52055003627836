import { React, useEffect, useState, useContext, useRef } from "react";
import gradientHome from "../assets/header/gradient_home.png";
import gradientHymn from "../assets/header/gradient_hymn.png";
import logoWhite from "../assets/header/logo_white.png";
import { Link, useLocation } from "react-router-dom";
import { ChordsContext } from "../components/ChordsContext";
import "./NavBar.css";

const NavBar = () => {
  const navbarRef = useRef(null);
  const [isHomePage, setIsHomePage] = useState(true);
  const [navbarHeight, setNavbarHeight] = useState("30vh");
  const [headerTitle, setHeaderTitle] = useState("hymnal.gr");
  const [backgroundImage, setBackgroundImage] = useState(gradientHome);
  const { setSearch } = useContext(ChordsContext);

  const routes = {
    "/": "Αρχική",
    "/Index": "Ευρετήριο Ύμνων",
    "/Favorites": "Αγαπημένα",
    "/Search": "Αναζήτηση",
    "/Tuner": "Κουρδιστήρι",
    "/FileUpload": "Ανέβασμα",
    "/Login": "Σύνδεση",
    "/Category": "Κατηγορία",
  };
  const location = useLocation();
  let pageTitle = routes[location.pathname];
  let isHymnPage = false;
  if (location.pathname === "/Hymn") {
    let params = new URLSearchParams(location.search);
    pageTitle = params.get("title");
    isHymnPage = true;
  }
  if (location.pathname === "/Category") {
    let params = new URLSearchParams(location.search);
    pageTitle = params.get("title");
  }

  useEffect(() => {
    setSearch("");
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    const logoTextElement = navbarRef.current.querySelector(".logo-hymn");
    if (logoTextElement) {
      const logoTextHeight = logoTextElement.clientHeight;
      const windowHeight = window.innerHeight;
      const additionalHeight = windowHeight > 1024 ? 100 : 50;
      const newNavbarHeight = Math.max(logoTextHeight + additionalHeight, 30);

      setNavbarHeight(`${newNavbarHeight}px`);
    }
  }, [headerTitle]);

  useEffect(() => {
    setBackgroundImage(location.pathname === "/" ? gradientHome : gradientHymn);
    setIsHomePage(location.pathname === "/");
    setNavbarHeight(location.pathname === "/" ? "30vh" : "15vh");
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    if (isHymnPage) {
      setHeaderTitle(pageTitle);
    } else {
      setHeaderTitle("hymnal.gr");
    }
    // eslint-disable-next-line
  }, [isHymnPage]);

  return (
    <div
      className="navbar"
      ref={navbarRef}
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: navbarHeight,
        width: "100%",
        borderBottomLeftRadius: "3rem",
        borderBottomRightRadius: "3rem",
      }}
    >
      {/* first row */}
      <div className="logo-container">
        <Link to="/" className="logo-img">
          <img src={logoWhite} alt="logo" />
          {!isHymnPage && <span className="logo-text">hymnal.gr</span>}
        </Link>
      </div>
      {isHymnPage && <span className="logo-hymn">{headerTitle}</span>}
      {!isHymnPage && !isHomePage && (
        <div className="page-title">{pageTitle}</div>
      )}
      {/* second row */}
      {isHomePage && (
        <div className="home-info pt-3">
          <div className="home-title">hymnal.gr</div>
          <div className="home-subtitle pt-2">
            Συγχορδίες <br /> & νότες για ύμνους
          </div>
        </div>
      )}
    </div>
  );
};

export default NavBar;
