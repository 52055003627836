import { openDB } from "idb";

const dbPromise = openDB("firebase-storage", 1, {
  upgrade(db) {
    db.createObjectStore("hymns");
  },
});

export const setItem = async (key, value) => {
  const db = await dbPromise;
  return db.put("hymns", value, key);
};

export const getItem = async (key) => {
  const db = await dbPromise;
  return db.get("hymns", key);
};
