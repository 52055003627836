import Toast from 'react-bootstrap/Toast';

export const ToastService = ({ message }) => {
  return (
    <div className="pt-3">
        <Toast>
            <Toast.Body>{message}</Toast.Body>
        </Toast>
    </div>    
  );
}
