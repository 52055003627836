import {
  React,
  useEffect,
  useContext,
  useState,
  useRef,
  createRef,
} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { List, ListItem } from "@material-ui/core";
import { useNavigate, createSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import AddToFavoritesIcon from "../assets/hymn/add_to_favorites.png";
import AddToFavoritesSelectedIcon from "../assets/hymn/add_to_favorites_selected.png";
import { ChordsContext } from "../components/ChordsContext";
import AddToFavorites from "../components/AddToFavorites";
import "./HymnsIndex.css";

export const HymnsIndex = () => {
  const navigate = useNavigate();
  const { setSearch, setResults, allHymns } = useContext(ChordsContext);
  const [showAddToFavoritesModal, setShowAddToFavoritesModal] = useState(false);
  const [addHymnToFavorites, setAddHymnToFavorites] = useState(null);
  const [selectedHymnIcons, setSelectedHymnIcons] = useState({});
  const scrollPositionRef = useRef(0);
  const boxRef = useRef(null);
  const letterRefs = useRef({});

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem("scrollPosition", scrollPositionRef.current);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const storedScrollPosition = localStorage.getItem("scrollPosition");
    if (storedScrollPosition) {
      boxRef.current.scrollTop = parseInt(storedScrollPosition, 10);
    }

    const storedFavorites = JSON.parse(localStorage.getItem("favorites")) || [];
    const hymnIcons = {};

    allHymns.forEach((hymn) => {
      const initialSelectedCategories = storedFavorites.reduce(
        (acc, item, index) => {
          if (item.content && item.content.includes(hymn.title)) {
            acc.push(index);
          }
          return acc;
        },
        []
      );
      hymnIcons[hymn.title] = initialSelectedCategories.length > 0;
    });

    setSelectedHymnIcons(hymnIcons);
  }, [allHymns, showAddToFavoritesModal]);

  const handleClick = (value) => {
    localStorage.setItem("scrollPosition", scrollPositionRef.current);
    setSearch("");
    setResults([]);
    navigate({
      pathname: "/Hymn",
      search: createSearchParams({
        title: value,
      }).toString(),
    });
  };

  const handleAddToFavorites = (item) => {
    setShowAddToFavoritesModal(!showAddToFavoritesModal);
    setAddHymnToFavorites(item.title);
  };

  const handleScroll = () => {
    scrollPositionRef.current = boxRef.current.scrollTop;
  };

  const scrollToLetter = (letter) => {
    const ref = letterRefs.current[letter];
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Utility function to remove accents
  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  // Extract unique initial letters without accents
  const initialLetters = Array.from(
    new Set(allHymns.map((hymn) => removeAccents(hymn.title[0].toUpperCase())))
  ).sort();

  // Initialize refs for each letter only once
  if (!Object.keys(letterRefs.current).length) {
    initialLetters.forEach((letter) => {
      letterRefs.current[letter] = createRef();
    });
  }

  // Track the first occurrence of each letter
  const seenLetters = new Set();

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          height: "70vh",
          overflowY: "auto",
          padding: "10px",
          borderRight: "1px solid #E4E4E4",
          alignContent: "center",
          textAlign: "center",
        }}
      >
        {initialLetters.map((letter) => (
          <div
            key={letter}
            onClick={() => scrollToLetter(letter)}
            style={{ cursor: "pointer", margin: "5px 0" }}
          >
            {letter}
          </div>
        ))}
      </div>
      <Box
        ref={boxRef}
        onScroll={handleScroll}
        sx={{
          width: "100%",
          height: "74vh",
          overflowY: "auto",
          bgcolor: "background.paper",
        }}
      >
        <nav>
          <List>
            {allHymns.map((item, index) => {
              const firstLetter = removeAccents(item.title[0].toUpperCase());
              if (!seenLetters.has(firstLetter)) {
                seenLetters.add(firstLetter);
                return (
                  <ListItem
                    key={index}
                    divider
                    onClick={() => handleClick(item.title)}
                    ref={letterRefs.current[firstLetter]}
                    style={{ color: item.visible ? "inherit" : "red" }}
                    sx={{ paddingLeft: 0 }}
                  >
                    <ListItemButton>
                      <ListItemText
                        primary={`${item.title} ${
                          item.number ? `(${item.number})` : ""
                        }`}
                      />
                    </ListItemButton>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAddToFavorites(item);
                      }}
                    >
                      <img
                        src={
                          selectedHymnIcons[item.title]
                            ? AddToFavoritesSelectedIcon
                            : AddToFavoritesIcon
                        }
                        alt="Add to Favorites"
                        style={{ width: 30, height: 30 }}
                      />
                    </IconButton>
                  </ListItem>
                );
              } else {
                return (
                  <ListItem
                    key={index}
                    divider
                    onClick={() => handleClick(item.title)}
                    style={{ color: item.visible ? "inherit" : "red" }}
                  >
                    <ListItemButton>
                      <ListItemText
                        primary={`${item.title} ${
                          item.number ? `(${item.number})` : ""
                        }`}
                      />
                    </ListItemButton>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAddToFavorites(item);
                      }}
                    >
                      <img
                        src={
                          selectedHymnIcons[item.title]
                            ? AddToFavoritesSelectedIcon
                            : AddToFavoritesIcon
                        }
                        alt="Add to Favorites"
                        style={{ width: 30, height: 30 }}
                      />
                    </IconButton>
                  </ListItem>
                );
              }
            })}
          </List>
        </nav>
        <footer
          style={{
            padding: "1rem",
            textAlign: "right",
            fontFamily: "'Manrope', sans-serif",
          }}
        >
          Σύνολο: {allHymns.length} από {allHymns.length}
        </footer>
      </Box>
      <AddToFavorites
        showAddToFavoritesModal={showAddToFavoritesModal}
        setShowAddToFavoritesModal={setShowAddToFavoritesModal}
        addHymnToFavorites={addHymnToFavorites}
      ></AddToFavorites>
    </div>
  );
};
