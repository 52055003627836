import React, { useEffect, useRef } from "react";

const FrequencyBars = ({ data }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const canvasContext = canvas.getContext("2d");
    const length = 64; // low frequency only
    const barWidth = canvas.width / length - 0.5;
    const maxBarHeight = canvas.height;
    const scalingFactor = maxBarHeight / 255;

    const updateCanvas = () => {
      canvasContext.clearRect(0, 0, canvas.width, canvas.height);
      for (let i = 0; i < length; i += 1) {
        const barHeight = data[i] * scalingFactor;
        canvasContext.fillStyle = "#ecf0f1";
        canvasContext.fillRect(
          i * (barWidth + 0.5),
          canvas.height - barHeight,
          barWidth,
          barHeight
        );
      }
    };

    updateCanvas();

    return () => {
      canvasContext.clearRect(
        0,
        0,
        canvas.width,
        document.body.clientHeight / 2
      );
    };
  }, [data]);

  return <canvas ref={canvasRef} style={{ width: "100%" }} />;
};

export const Meter = function (selector) {
  this.$root = document.querySelector(selector);
  this.$pointer = this.$root.querySelector(".meter-pointer");
  this.init();
};

Meter.prototype.init = function () {
  for (var i = 0; i <= 10; i += 1) {
    const $scale = document.createElement("div");
    $scale.className = "meter-scale";
    $scale.style.transform = "rotate(" + (i * 9 - 45) + "deg)";
    if (i % 5 === 0) {
      $scale.classList.add("meter-scale-strong");
    }
    this.$root.appendChild($scale);
  }
};

Meter.prototype.update = function (deg) {
  this.$pointer.style.transform = "rotate(" + deg + "deg)";
};

export const Notes = function (selector, tuner) {
  this.tuner = tuner;
  this.isAutoMode = true;
  this.$root = document.querySelector(selector);
  this.$notesList = this.$root.querySelector(".notes-list");
  this.$frequency = this.$root.querySelector(".frequency");
  this.$notes = [];
  this.$notesMap = {};
  this.createNotes();
  this.$notesList.addEventListener("touchstart", (event) =>
    event.stopPropagation()
  );
};

Notes.prototype.createNotes = function () {
  this.$notesList.innerHTML = "";
  const minOctave = 1;
  const maxOctave = 8;
  for (var octave = minOctave; octave <= maxOctave; octave += 1) {
    for (var n = 0; n < 12; n += 1) {
      const $note = document.createElement("div");
      $note.className = "note";
      $note.dataset.name = this.tuner.noteStrings[n];
      $note.dataset.value = 12 * (octave + 1) + n;
      $note.dataset.octave = octave.toString();
      $note.dataset.frequency = this.tuner.getStandardFrequency(
        $note.dataset.value
      );
      $note.innerHTML =
        $note.dataset.name[0] +
        '<span class="note-sharp">' +
        ($note.dataset.name[1] || "") +
        "</span>" +
        '<span class="note-octave">' +
        $note.dataset.octave +
        "</span>";
      this.$notesList.appendChild($note);
      this.$notes.push($note);
      this.$notesMap[$note.dataset.value] = $note;
    }
  }

  const self = this;
  this.$notes.forEach(function ($note) {
    $note.addEventListener("click", function () {
      if (self.isAutoMode) {
        return;
      }

      const $active = self.$notesList.querySelector(".active");
      if ($active === this) {
        self.tuner.stopOscillator();
        $active.classList.remove("active");
      } else {
        self.tuner.play(this.dataset.frequency);
        self.update($note.dataset);
      }
    });
  });
};

Notes.prototype.active = function ($note) {
  this.clearActive();
  $note.classList.add("active");
  this.$notesList.scrollLeft =
    $note.offsetLeft - (this.$notesList.clientWidth - $note.clientWidth) / 2;
};

Notes.prototype.clearActive = function () {
  const $active = this.$notesList.querySelector(".active");
  if ($active) {
    $active.classList.remove("active");
  }
};

Notes.prototype.update = function (note) {
  if (note.value in this.$notesMap) {
    this.active(this.$notesMap[note.value]);
    this.$frequency.childNodes[0].textContent = parseFloat(
      note.frequency
    ).toFixed(1);
  }
};

Notes.prototype.toggleAutoMode = function () {
  if (!this.isAutoMode) {
    this.tuner.stopOscillator();
  }
  this.clearActive();
  this.isAutoMode = !this.isAutoMode;
};

export class TunerCore {
  constructor() {
    this.onNoteDetected = null;
    this.middleA = 440;
    this.semitone = 69;
    this.bufferSize = 4096;
    this.noteStrings = [
      "C",
      "C♯",
      "D",
      "D♯",
      "E",
      "F",
      "F♯",
      "G",
      "G♯",
      "A",
      "A♯",
      "B",
    ];
    this.audioContext = null;
    this.analyser = null;
    this.scriptProcessor = null;
    this.pitchDetector = null;
    this.oscillator = null;
    this.initGetUserMedia();
  }

  initGetUserMedia() {
    window.AudioContext = window.AudioContext || window.webkitAudioContext;
    if (!window.AudioContext) {
      alert("AudioContext not supported");
      return;
    }

    if (!navigator.mediaDevices) {
      navigator.mediaDevices = {};
    }

    if (!navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia = function (constraints) {
        const getUserMedia =
          navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

        if (!getUserMedia) {
          alert("getUserMedia is not implemented in this browser");
          return;
        }

        return new Promise((resolve, reject) => {
          getUserMedia.call(navigator, constraints, resolve, reject);
        });
      };
    }
  }

  getFrequencyData() {
    if (this.analyser) {
      const bufferLength = this.analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);
      this.analyser.getByteFrequencyData(dataArray);
      return dataArray;
    }
    return [];
  }

  async startRecord() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.audioContext = new window.AudioContext();
      const source = this.audioContext.createMediaStreamSource(stream);
      this.analyser = this.audioContext.createAnalyser();
      this.scriptProcessor = this.audioContext.createScriptProcessor(
        this.bufferSize,
        1,
        1
      );

      source.connect(this.analyser);
      this.analyser.connect(this.scriptProcessor);
      this.scriptProcessor.connect(this.audioContext.destination);

      this.scriptProcessor.addEventListener("audioprocess", (event) => {
        const frequency = this.pitchDetector.do(
          event.inputBuffer.getChannelData(0)
        );
        if (frequency && this.onNoteDetected) {
          const note = this.getNote(frequency);
          this.onNoteDetected({
            name: this.noteStrings[note % 12],
            value: note,
            cents: this.getCents(frequency, note),
            octave: Math.floor(note / 12) - 1,
            frequency,
          });
        }
      });
    } catch (error) {
      alert(`${error.name}: ${error.message}`);
    }
  }

  async init() {
    this.audioContext = new window.AudioContext();
    this.analyser = this.audioContext.createAnalyser();
    this.scriptProcessor = this.audioContext.createScriptProcessor(
      this.bufferSize,
      1,
      1
    );

    const aubioModule = await window.aubio();
    this.pitchDetector = new aubioModule.Pitch(
      "default",
      this.bufferSize,
      1,
      this.audioContext.sampleRate
    );
    this.startRecord();
  }

  getNote(frequency) {
    const note = 12 * (Math.log(frequency / this.middleA) / Math.log(2));
    return Math.round(note) + this.semitone;
  }

  getStandardFrequency(note) {
    return this.middleA * Math.pow(2, (note - this.semitone) / 12);
  }

  getCents(frequency, note) {
    return Math.floor(
      (1200 * Math.log(frequency / this.getStandardFrequency(note))) /
        Math.log(2)
    );
  }

  play(frequency) {
    if (!this.oscillator) {
      this.oscillator = this.audioContext.createOscillator();
      this.oscillator.connect(this.audioContext.destination);
      this.oscillator.start();
    }
    this.oscillator.frequency.value = frequency;
  }

  stopOscillator() {
    if (this.oscillator) {
      this.oscillator.disconnect();
      this.oscillator.stop();
      this.oscillator = null;
    }
  }
}

export default FrequencyBars;
