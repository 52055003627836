import React from "react";

export const NotFound = () => {
  return (
    <div>
      <img
        src={require("../assets/images/error-404.jpg")}
        alt=""
        style={{ height: "80vh" }}
      />
    </div>
  );
};
