export const majorChords = [
  {
    value: "C",
    label: "C",
    image: require("../assets/images/c_major_chord.png"),
    sound: "../../sounds/c_major_sound.mp3",
  },
  {
    value: "C#",
    label: "C#",
    image: require("../assets/images/c_sharp_major_chord.png"),
    sound: "../../sounds/c_sharp_major_sound.mp3",
  },
  {
    value: "D",
    label: "D",
    image: require("../assets/images/d_major_chord.png"),
    sound: "../../sounds/d_major_sound.mp3",
  },
  {
    value: "D#",
    label: "D#",
    image: require("../assets/images/d_sharp_major_chord.png"),
    sound: "../../sounds/d_sharp_major_sound.mp3",
  },
  {
    value: "E",
    label: "E",
    image: require("../assets/images/e_major_chord.png"),
    sound: "../../sounds/e_major_sound.mp3",
  },
  {
    value: "F",
    label: "F",
    image: require("../assets/images/f_major_chord.png"),
    sound: "../../sounds/f_major_sound.mp3",
  },
  {
    value: "F#",
    label: "F#",
    image: require("../assets/images/f_sharp_major_chord.png"),
    sound: "../../sounds/f_sharp_major_sound.mp3",
  },
  {
    value: "G",
    label: "G",
    image: require("../assets/images/g_major_chord.png"),
    sound: "../../sounds/g_major_sound.mp3",
  },
  {
    value: "G#",
    label: "G#",
    image: require("../assets/images/g_sharp_major_chord.png"),
    sound: "../../sounds/g_sharp_major_sound.mp3",
  },
  {
    value: "A",
    label: "A",
    image: require("../assets/images/a_major_chord.png"),
    sound: "../../sounds/a_major_sound.mp3",
  },
  {
    value: "A#",
    label: "A#",
    image: require("../assets/images/a_sharp_major_chord.png"),
    sound: "../../sounds/a_sharp_major_sound.mp3",
  },
  {
    value: "B",
    label: "B",
    image: require("../assets/images/b_major_chord.png"),
    sound: "../../sounds/b_major_sound.mp3",
  },
];

export const minorChords = [
  {
    value: "Cm",
    label: "Cm",
    image: require("../assets/images/c_minor_chord.png"),
    sound: "../../sounds/c_minor_sound.mp3",
  },
  {
    value: "C#m",
    label: "C#m",
    image: require("../assets/images/c_sharp_minor_chord.png"),
    sound: "../../sounds/c_sharp_minor_sound.mp3",
  },
  {
    value: "Dm",
    label: "Dm",
    image: require("../assets/images/d_minor_chord.png"),
    sound: "../../sounds/d_minor_sound.mp3",
  },
  {
    value: "D#m",
    label: "D#m",
    image: require("../assets/images/d_sharp_minor_chord.png"),
    sound: "../../sounds/d_sharp_minor_sound.mp3",
  },
  {
    value: "Em",
    label: "Em",
    image: require("../assets/images/e_minor_chord.png"),
    sound: "../../sounds/e_minor_sound.mp3",
  },
  {
    value: "Fm",
    label: "Fm",
    image: require("../assets/images/f_minor_chord.png"),
    sound: "../../sounds/f_minor_sound.mp3",
  },
  {
    value: "F#m",
    label: "F#m",
    image: require("../assets/images/f_sharp_minor_chord.png"),
    sound: "../../sounds/f_sharp_minor_sound.mp3",
  },
  {
    value: "Gm",
    label: "Gm",
    image: require("../assets/images/g_minor_chord.png"),
    sound: "../../sounds/g_minor_sound.mp3",
  },
  {
    value: "G#m",
    label: "G#m",
    image: require("../assets/images/g_sharp_minor_chord.png"),
    sound: "../../sounds/g_sharp_minor_sound.mp3",
  },
  {
    value: "Am",
    label: "Am",
    image: require("../assets/images/a_minor_chord.png"),
    sound: "../../sounds/a_minor_sound.mp3",
  },
  {
    value: "A#m",
    label: "A#m",
    image: require("../assets/images/a_sharp_minor_chord.png"),
    sound: "../../sounds/a_sharp_minor_sound.mp3",
  },
  {
    value: "Bm",
    label: "Bm",
    image: require("../assets/images/b_minor_chord.png"),
    sound: "../../sounds/b_minor_sound.mp3",
  },
];

export const seventhChords = [
  {
    value: "C7",
    label: "C7",
    image: require("../assets/images/c_seventh_chord.png"),
    sound: "../../sounds/c_seventh_sound.mp3",
  },
  {
    value: "C#7",
    label: "C#7",
    image: require("../assets/images/c_sharp_seventh_chord.png"),
    sound: "../../sounds/c_sharp_seventh_sound.mp3",
  },
  {
    value: "D7",
    label: "D7",
    image: require("../assets/images/d_seventh_chord.png"),
    sound: "../../sounds/d_seventh_sound.mp3",
  },
  {
    value: "D#7",
    label: "D#7",
    image: require("../assets/images/d_sharp_seventh_chord.png"),
    sound: "../../sounds/d_sharp_seventh_sound.mp3",
  },
  {
    value: "E7",
    label: "E7",
    image: require("../assets/images/e_seventh_chord.png"),
    sound: "../../sounds/e_seventh_sound.mp3",
  },
  {
    value: "F7",
    label: "F7",
    image: require("../assets/images/f_seventh_chord.png"),
    sound: "../../sounds/f_seventh_sound.mp3",
  },
  {
    value: "F#7",
    label: "F#7",
    image: require("../assets/images/f_sharp_seventh_chord.png"),
    sound: "../../sounds/f_sharp_seventh_sound.mp3",
  },
  {
    value: "G7",
    label: "G7",
    image: require("../assets/images/g_seventh_chord.png"),
    sound: "../../sounds/g_seventh_sound.mp3",
  },
  {
    value: "G#7",
    label: "G#7",
    image: require("../assets/images/g_sharp_seventh_chord.png"),
    sound: "../../sounds/g_sharp_seventh_sound.mp3",
  },
  {
    value: "A7",
    label: "A7",
    image: require("../assets/images/a_seventh_chord.png"),
    sound: "../../sounds/a_seventh_sound.mp3",
  },
  {
    value: "A#7",
    label: "A#7",
    image: require("../assets/images/a_sharp_seventh_chord.png"),
    sound: "../../sounds/a_sharp_seventh_sound.mp3",
  },
  {
    value: "B7",
    label: "B7",
    image: require("../assets/images/b_seventh_chord.png"),
    sound: "../../sounds/b_seventh_sound.mp3",
  },
];

export const variantChords = [
  {
    value: "C#",
    label: "Db",
    image: require("../assets/images/b_seventh_chord.png"),
    sound: "../../sounds/b_seventh_sound.mp3",
  },
  {
    value: "Eb",
    label: "Eb",
    image: require("../assets/images/b_seventh_chord.png"),
    sound: "../../sounds/b_seventh_sound.mp3",
  },
  {
    value: "Gb",
    label: "Gb",
    image: require("../assets/images/b_seventh_chord.png"),
    sound: "../../sounds/b_seventh_sound.mp3",
  },
  {
    value: "Ab",
    label: "Ab",
    image: require("../assets/images/b_seventh_chord.png"),
    sound: "../../sounds/b_seventh_sound.mp3",
  },
  {
    value: "Bb",
    label: "Bb",
    image: require("../assets/images/b_seventh_chord.png"),
    sound: "../../sounds/b_seventh_sound.mp3",
  },
  {
    value: "Dbm",
    label: "Dbm",
    image: require("../assets/images/b_seventh_chord.png"),
    sound: "../../sounds/b_seventh_sound.mp3",
  },
  {
    value: "Ebm",
    label: "Ebm",
    image: require("../assets/images/b_seventh_chord.png"),
    sound: "../../sounds/b_seventh_sound.mp3",
  },
  {
    value: "Gbm",
    label: "Gbm",
    image: require("../assets/images/b_seventh_chord.png"),
    sound: "../../sounds/b_seventh_sound.mp3",
  },
  {
    value: "Abm",
    label: "Abm",
    image: require("../assets/images/b_seventh_chord.png"),
    sound: "../../sounds/b_seventh_sound.mp3",
  },
  {
    value: "Bbm",
    label: "Bbm",
    image: require("../assets/images/b_seventh_chord.png"),
    sound: "../../sounds/b_seventh_sound.mp3",
  },
];

export const symbols = [
  {
    value: "/",
    label: "/",
  },
];
